/* eslint-disable max-len */

import {
  DANONE_USER_ACCESS_TYPE,
  DERIVED_SHIPMENT_STATUSES,
  FIRST_MILE_USER_ACCESS_TYPE,
  JOB_STATUSES,
  SHIPMENT_POINT_STATUS,
  SHIPMENT_STATUSES,
  USER_ACCESS_TYPE,
} from '~/Configurations/constants';

export default {
  add_account_helper_text: 'Add bank account number to be added in invoice',
  add_detail_below_to_help_you: 'Add the details below to help you',
  all_employees_are_able_to_access_all_shipper_projects_below_parent_compnay: 'All employees are able to access all shipper projects below the parent company.',
  already_have_an_account: 'Already have an account?',
  average_time_for_vendor_to_accept_confirm_fulfillment:
    'Average time for a vendor to accept/confirm fulfillment on shipments',
  bank_account_successfully_saved: 'Bank account successfully saved',
  bid_choose_winner_setting_msg:
    'We\'ll choose a winner for you if you haven\'t picked one after a certain period of time.',
  bill_of_lading_successfully_deleted: 'Bill of Lading successfully deleted',
  bill_of_lading_successfully_saved: 'Bill of Lading successfully saved',
  cancel_do_warning: 'This shipment will still be saved, but will not be able to be re-actived.',
  cancellation_do_warning: 'Transporter will get notified about your cancellation.',
  cancellation_edit_do_vendor_warning: 'Please confirm the changes to the vendor and other related parties',
  change_shipment_status_message_title: 'Change status will...',
  changes_successfully_saved: 'Changes Successfully Saved.',
  choose_company_placeholder: 'Or enter your company name here',
  choose_container_to_edit: 'Choose container to edit',
  choose_type_of_goods: 'Choose goods type',
  completed_fulfilled_shipments: 'Completed/Fulfilled shipments',
  confirmation: 'Confirmation',
  contract_removal_msg: 'Once you delete the Contract, The Contract will be deleted permanently.',
  contract_successfully_deleted: 'Contract successfully deleted.',
  customer_central_address_help_field: 'This address will be shown as customer address when creating invoice',
  delete_bill_of_lading_warning:
    'Bill of Lading and all data related (including document and container) will be deleted. This action is permanent.',
  delete_document_picture: 'Are you sure you want to delete this picture?',
  delete_document_warning: 'You will delete document permanently',
  do_not_eligible_to_rated: 'DO not eligible to rated',
  dont_have_an_account_yet: 'Don\'t have an account yet?',
  download_locations_csv_msg: 'Or download the template {{name}}',
  employee_successfully_deactivated: 'Employee successfully deactivated',
  empty_multistop_fee: 'Let\'s complete your Multistop Fee.',
  empty_notification: 'Empty Notifications',
  enjoying_the_experience: 'Enjoying the experience?',
  enter_posted_price_and_max_bid_limit: 'Enter Posted Price and Max Bid Limit',
  enter_valid_number_with_upto_2_decimal: 'Enter valid value with up to 2 decimals',
  enter_verification_code_that_we_sent_to: 'We just sent a verification code to:',
  errors: {
    backend: {
      approveDofRequest: {
        83: 'Pengajuan pembayaran cepat tidak terList di sistem',
        84: 'Satu atau beberapa pengiriman yang Rejected bukan bagian dari pengajuan pembayaran ini',
      },
      assignPlannedShipment: {
        108: 'Shipment is not assignable(current status is not PLANNED)',
        131: 'Company Vehicle has ongoing shipment',
        132: 'Company Driver has ongoing shipment',
      },
      assignShipperSalesAccess: {
        465: 'Email already in use',
        500: 'The employee already assigned',
      },
      changeShipmentStatus: {
        215: 'Status has been changed. Please refresh the page',
        216: 'Location seems too far',
        220: 'Shipment has been cancelled. Please check with your team and click Cancel to proceed',
      },
      checkDuplicateDoNumber: {
        596: 'Order No. Existed',
      },
      common: {
        401: 'You don\'t have access',
        500: 'Internal Server Error',
        '018-5500': 'Internal Server Error',
      },
      createBillOfLading: {
        94: 'BL Number already used',
      },
      createCompanyConstant: {
        1: 'Company does not exist',
        54: 'Company constant name should be unique',
        55: 'Company constant ID should be unique',
        440: 'User with given ksuid is not an employee of the given company',
        500: 'Internal server error',
      },
      createContainer: {
        110: 'Duplicate container number on the same BL',
        111: 'Duplicate seal number on the same BL',
        112: 'Duplicate container and seal number on the same BL',
      },
      createContainers: {
        422: 'Duplicate container or seal number on the same BL',
      },
      createFirstMileInvoice: {
        422: 'Invoice number has been used before, it must be unique',
      },
      createShipperEmployee: {
        35: 'Phone number already in use',
        465: 'Email already in use',
      },
      createSmeSales: {
        463: 'Email domain already whitelisted',
        464: 'Email already taken as admin',
        465: 'Email aready taken within company',
        466: 'Phone number already taken',
        467: 'Phone number already taken as admin',
        468: 'Email not invited or whitelisted',
        469: 'Failed to send verification mail',
        470: 'Company name should be unique',
        471: 'User already Invited',
        484: 'Email already taken',
      },
      deactivateCompanyDriver: {
        6: 'Company driver does not exists.',
        10: 'Driver belongs to a different company',
        58: 'Company Driver has ongoing shipment',
      },
      deactivateCompanyVehicle: {
        2: 'Company vehicle does not exists',
        10: 'Vehicle belongs to a different company',
        57: 'Company Vehicle has ongoing shipment',
      },
      editBillOfLading: {
        94: 'BL Number already used',
        422: 'Store cannot be changed',
      },
      generateCompanyHandles: {
        34: 'Please use only alpha numeric and lower case',
      },
      resetPassword: {
        18: 'Invalid verification code',
      },
      saveBrokerageVendor: {
        34: 'Please use only alpha numeric and lower case for Registration ID',
      },
      saveCompanyDetails: {
        34: 'Please use only alpha numeric and lower case',
      },
      saveCompanyMultistopFee: {
        '008-002': 'You already have this fee.',
      },
      saveCompanyProduct: {
        3001: 'SKU ID has been registered',
        3002: 'SKU Name has been registered',
        3003: 'Invalid Group ID',
        3004: 'SKU not found',
      },
      saveCompanyVehicle: {
        1: 'Company Name tidak terdaftar',
        2: 'Armada belum tidak terdaftar',
        3: 'Gagal mengUpload STNK',
        4: 'Gagal mengUpload KIR',
        14: 'Armada sudah terdaftar',
        15: 'Tipe kendaraan tidak valid',
      },
      saveCompanyVehicles: {
        422: 'Ada beberapa data yang tidak valid',
      },
      saveCustomer: {
        1: 'Company not registered',
        5: 'Customer\'s company not registered',
        484: 'Vendor code already taken',
        485: 'Vendor ID already taken',
      },
      saveDeliveryOrder: {
        137: 'Duplicate Product ID',
        198: 'Location error. Please update your location.',
        567: 'Location changed. Please refresh the page to update info',
        651: 'This edit cannot be saved. Vendor already confirmed your shipment.',
      },
      saveDeliveryOrders: {
        53: 'Invalid unloading location',
        54: 'Invalid loading location',
        56: 'Invalid SKU',
        58: 'Invalid loads capacity unit',
        63: 'Invalid unloading date',
        66: 'Invalid customer',
        74: 'Company does not exist',
        105: 'Contract to given transporter does not exist',
        113: 'Invalid order number',
        114: 'Invalid shipment type',
        115: 'Invalid latest_pickup_datetime',
        116: 'Invalid location ID',
        117: 'Invalid truck type',
        118: 'Invalid truck subtype',
        119: 'Truck type and/or truck subtype combination not found',
        120: 'Invalid loads (delivery order) capacity',
        121: 'Company shipment type not found',
        122: 'Multiple company shipment type found',
        123: 'Company order type not found',
        124: 'Multiple company order type found',
        125: 'Invalid transporter ID and/or name',
        126: 'Pickup Date and Time must be earlier than Latest Pickup Date and Time',
        127: 'Dropoff Date and Time must be later than Pickup Date and Time',
        128: 'Latest Pickup Date and Time must be earlier than Dropoff Date and Time',
        137: 'Duplicate Company Product (SKU) found on the same DO',
        140: 'Invalid window time',
        141: 'Invalid timezone',
        142: 'Invalid company product (SKU)',
        143: 'Invalid company product (SKU) quantity',
        144: 'Duplicate Company Product (SKU) found on the same DO',
        156: 'Invalid fulfillment type (It should be either blank or belongs to SINGLE_CONTRACTED, BROADCAST_CONTRACTED or PRIVATE_BIDDING)',
        157: 'Transporter_id should be blank',
        158: 'Contracted transporters for the given route and shipment type does not exist',
        159: 'Non-contracted transporters for the given route & shipment type does not exist',
        185: 'Company Product (SKU) CBM or Weight is required',
        188: 'Capacity (weight) must be less than 28 TON',
        190: 'Capacity (weight) must be greater than 0',
        193: 'Truck Selection by Vendor must be On or Off',
        194: 'Truck Type and Sub Type should be blank if Truck Selection by Vendor is On',
        422: 'Invalid CSV parameter/attributes',
        482: 'Hmm you need to input this field if it is a single shipment type',
        483: 'Input a date and time format using DD-MM-YYYY HH:MM',
        484: 'Input an active Location registered in B-TMS',
        485: 'Input an active Location registered in B-TMS',
        486: 'Hmm, there is no shipper with this name.',
        488: 'Hmm, this truck type doesn\'t have Overnight Fees registered for this Shipper and Shipment Type. Let\'s pick another one.',
        490: 'Hmm..this Location doesnt have a Loading Fee for the chosen Shipper, Truck Type, Body Type, and Shipment Type',
        491: 'Hmm..this Location doesnt have a Unloading Fee for the chosen Shipper, Truck Type, Body Type, and Shipment Type',
        493: 'Deadline datetime must be in DD/MM/YYYY format.',
        494: 'Input a date and time format using DD-MM-YYYY HH:MM',
        497: 'Hmm all Group Loads must be posted to Marketplace, try again with "Yes" or "TRUE"',
        498: 'Hmm Per Unit Price Rate Type cannot be posted to Marketplace, try again with "No" or "FALSE"',
        499: 'Hmm, for a Group Load, number of external identifiers must match the number of shipments (with each external identifier separated by semicolon) or must be left blank; for a non-Group Load, enter one external identifier or leave blank',
        501: 'Posted DO  must have origin or posted price.',
        502: 'Put a price you\'d like to post in Marketplace or use our recommended price.',
        505: 'Hmm, this price_rate_type doesn\'t match the Goods Capacity Unit. Pick "Per Route" (or "Route"), "Per {Capacity Unit}" (or "Capacity Unit")',
        506: 'Cannot post capacity unit.',
        507: 'Input YES/TRUE or NO/FALSE',
        508: 'Only posted DO have max transported bid price',
        509: 'Hmm the value here must match the Max Bid Limit (%)',
        510: 'Only posted DO have max bid limit percentage.',
        512: 'Hmm, enter 1 or leave blank to create a Group Load; enter more than 1 and less than 21 to create a Group Load',
        513: 'Hmm, this shipment type doesn\'t seem right. Pick "f2w", "w2w", "f2dist", "w2dist", "f2mt", "w2depo", "f2depo", "w2mt", "mt", "gt", "multidrop", "multipickup", or "multidrop_multipickup".',
        516: 'Hmm, this truck type doesn\'t exist. Let\'s pick another one.',
        517: 'Hmm, this truck bodytype doesn\'t exist. Let\'s pick another one.',
        518: 'Use 0 or positive number with up to 8 decimal points here.',
        519: 'Hmm, this capacity_unit doesn\'t seem right. Pick "Kg", "CBM", "Unit"',
        523: 'Hmmm you must input a positive number up to 8 decimal points',
        524: 'Hmmm you must input a number from 0-100',
        525: 'Hmmm you must input a positive number up to 8 decimal points',
        526: 'Hmm, this Shipper doesn\'t have this price_rate_type registered for this shipment. Let\'s register the Shipper Price',
        530: 'Input valid shipment type',
        531: 'Invalid point type',
        532: 'Input a date and time format using YYYY-MM-DD HH:MM:SS',
        533: 'Hmm number of locations inputted must be equal to the number of pickup and dropoff points and they must be unique from each other',
        534: 'Input a Location registered in B-TMS',
      },
      saveEmployee: {
        463: 'Email domain already whitelisted',
        464: 'Email already taken as admin',
        465: 'Email aready taken within company',
        466: 'Phone number already taken',
        467: 'Phone number already taken as admin',
        468: 'Email not invited or whitelisted',
        469: 'Failed to send verification mail',
        470: 'Company name should be unique',
        471: 'User already Invited',
        484: 'Email already taken',
      },
      savePartnershipPricing: {
        1001: 'Invalid Transporter',
        1002: 'You already have this contract',
        1003: 'Partnership pricing not found',
        1004: 'Failed to deletepartnership pricing',
        1005: 'Failed to generate lane ID',
        1006: 'Partnership lane empty',
      },
      savePartnershipPricings: {
        422: 'Invalid CSV parameter/attributes',
        1001: 'Invalid Transporter',
        1002: 'Duplicate Pricing',
        1003: 'Partnership pricing not found',
        1004: 'Failed to deletepartnership pricing',
        1005: 'Failed to generate lane ID',
        1006: 'Partnership lane empty',
      },
      saveWarehouse: {
        422: 'Some fields are already exist',
        490: 'Location ID already taken in company',
        491: 'Location code already taken in company',
      },
      saveWarehouseFee: {
        45: 'Location Fee already exists with same Fee Type, Shipment Type, Truck Type, and Body Type',
        46: 'Location Fee already exists with same Fee Type, Shipment Type, Truck Type, and Body Type',
      },
      saveWarehouses: {
        422: 'Invalid CSV parameter/attributes',
        490: 'Location ID already taken in company',
        491: 'Location code already taken in company',
      },
      searchCompanyHandle: {
        1: 'Vendor not found, please reenter a valid ID',
      },
      updateCompanyConstant: {
        53: 'Company constant does not exist',
        54: 'Company constant name should be unique',
        55: 'Company constant ID should be unique',
        440: 'User with given ksuid is not an employee of the given company',
        500: 'Internal server error',
      },
      updateContainer: {
        110: 'Duplicate container number on the same BL',
        111: 'Duplicate seal number on the same BL',
        112: 'Duplicate container and seal number on the same BL',
      },
      updateDeliveryOrderPricePrivateBid: {
        153: 'You already set the price. Please reload this page.',
      },
      updateDeliveryOrderTransporter: {
        62: 'Pickup time/latest pickup time must be smaller than dropoff time.',
        126: 'Pickup Date and Time must be earlier than Latest Pickup Date and Time',
        127: 'Dropoff Date and Time must be later than Pickup Date and Time',
        128: 'Latest Pickup Date and Time must be earlier than Dropoff Date and Time',
      },
      updateSmeSales: {
        11: 'Employee does not exists',
        12: 'Invalid employee access type',
        27: 'Team leader ksuid missing',
        28: 'Team leader has child',
        500: 'Unknown error',
      },
      uploadContainers: {
        422: 'Invalid container file',
      },
      verifyVendor: {
        1: 'Vendor not registered',
      },
    },
    different_origin_and_destination: 'Origin and Destination must be different',
    error_code: {
      401: 'You don\'t have access',
      445: 'This number has not been registered. Try a different one.',
      500: 'Internal Server Error',
    },
    failed_submit_location_fee: 'Please fill out the mandatory fields before adding Location Fee(s)',
    network: {
      500: 'Internal Server Error',
      common: 'Check your connection',
    },
    otp_required_please_retry: 'Verification code required. Please retry',
    phone_number_already_registered: 'Phone Number Already Registered',
    price_not_registered_origin_destination: 'Price is not registered in either origin/destination.',
    price_rate_not_exist: 'Goods Capacity quantity or measure has no registered Price Rate Type',
    status_code: {
      401: 'You don\'t have access',
      500: 'Internal Server Error',
      '018-5500': 'Internal Server Error',
    },
    unexpected: 'Something is wrong, Team will investigate the issue',
    verification_code_mismatch: 'Incorrect verification code. Check your code and try again.',
  },
  fill_account_details: 'Register for an Account',
  general_successfully_saved: '{{name}} successfully saved.',
  generic_add: 'Add {{name}}',
  generic_edit: 'Edit {{name}}',
  generic_upload: 'Upload {{name}}',
  invalid_bank_account: 'Fill in valid account number',
  insert_time: 'Insert Time',
  invoice_accepted: 'Invoice successfully accepted',
  invoice_rejected: 'Invoice has been rejected',
  kargo_will_process_your_do: 'Kargo will be processing your DO. Please wait for the confirmation.',
  leave_bill_of_lading_form_warning: 'Bill of Lading will not get saved if you leave this page now.',
  leave_form_warning: 'Data will not get saved if you leave this page now.',
  leave_shipment_form_warning: 'Shipment will not get saved if you leave this page now.',
  leave_this_page_question: 'Are you sure to leave this page?',
  location_seems_to_far: 'Location Seems Too Far',
  logo_upload_helper: 'Logo must be in .jpg or .png format, minimal 300 px.',
  mass_do_cancellation_warning: 'Once you delete the Orders, The Order will be inactive.',
  max_bid_greater_than_posted_price: 'Max Bid Limit must be equal to or greater than Posted Price',
  no_contract_available: 'No Contract Available for This Route',
  no_contract_available_help_info:
    'In the meantime, this DO can only be assigned to a few selected vendors in a private marketplace',
  no_data_for_planned_dropoff_time: 'No data for planned dropoff time',
  no_multistop_fee_available: 'There is no multistop fee available for this route',
  no_vendor_in_marketplace: 'No vendor in Private Marketplace',
  now_employee_setting_applied_to_all_shipper_project: 'Now employee setting applied to all shipper project',
  note_csv_separator:
    'Note: You can check you current CSV separator by opening the file in Notepad (or any other text editor).',
  only_kargo_admin_can_edit: 'Only Kargo Admin can edit this information.',
  ontime_perfomace_of_pickup_dropoff: 'On-Time Perfomance of pickup/dropoff',
  order_has_expired: 'Order has expired',
  order_number_should_not_contain_comma: 'Order No. should not contain comma (,)',
  otp_instruction: 'Enter your new password, then enter the\nverification code we just sent to:',
  password_doesnt_match: 'Your password verification doesn\'t match your password',
  password_placeholder: 'Type password',
  password_successfully_changed: 'Password successfully changed',
  percentage_more_than_zero: 'Percentage (%) should be more than 0%',
  percentage_total: 'Percentage Total (%) should be 100%',
  phone_number_placeholder: 'Enter phone number',
  pickup_dropoff_date_and_time_should_be_sequential: 'Pickup/dropoff date and time should be sequential',
  please_choose_another_vendor: 'Please choose another vendor',
  please_choose_sorter_period: 'Please choose a shorter period',
  please_contact_kargo_sales_team_for_assistance: 'Please contact Kargo Sales team for assistance',
  please_fill_shipment_routes_pickup_date: 'Please fill in shipment routes and pickup date to load available vessel voyage schedule',
  please_select_reason: 'Please select a reason.',
  please_input_location_first: 'Please Input Location First',
  please_input_location_trucktype_first: 'Please Input Location & Truck type First',
  please_input_vendor_first: 'Please Input Vendor First',
  please_make_sure_you_are_still_within_the_radius_of_your_planned_location:
    'Please make sure you are still within the radius of your planned location',
  please_review_the_details_below: 'Please review the details below',
  please_wait_for_vessel_voyage_schedule: 'Please wait, loading the available vessel voyage schedule',
  price_successfully_added: 'Price Successfully Added',
  processing_mass_do_cancellation: 'Processing your Mass DO Cancellation.....',
  reset_password_success: 'Reset password successful.',
  schedule_not_found_cs_will_help: 'Schedule not found, our CS will help you to find vessel voyage based on your stuffing date',
  search_by_license_plate: 'Search by license plate',
  search_by_name: 'Search by name',
  search_name: 'Search Name',
  search_license_plate_or_order_no: 'Search License Plate, Order No.',
  select_one_aspect: 'Select at least one Aspect',
  select_current_csv_separator:
    'Please select your current CSV separator (comma, semicolon, etc.). The default is comma (,)',
  send_verification_code_failed: 'send_verification_code_failed',
  send_verification_code_success: 'Verification code sent.',
  set_up_detail_evaluation:
    'Set up the details below to help you evaluate shipment after the shipment has been completed.',
  set_up_document_upload_settings: 'Set up the document upload settings',
  set_up_rating_shipment: 'Set up which aspect(s) will be included when you rate completed shipments',
  set_up_load_rating_shipment: 'Set up the criteria to rate the shipment',
  shipment_allocation_choose_shipper_helper: 'Driver will get notification for this shipment.',
  shipment_fee_successfully_added: 'Shipment fee successfully added.',
  shipment_no_whitespace: 'Whitespace cannot be used as order number',
  shipment_status_map_multi: {
    [SHIPMENT_STATUSES.UPCOMING]: 'Upcoming',
    [SHIPMENT_STATUSES.PLANNED]: 'Planned',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truck Assigned',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'In Transit to Origin',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'At Origin {{sequence}}',
    [SHIPMENT_STATUSES.START_LOADING]: 'Start Loading {{sequence}}',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Finish Loading {{sequence}}',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'In Transit to Destination',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'Ongoing to Destination {{sequence}}',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Arrive at Destination',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Start Unloading {{sequence}}',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Finish Unloading {{sequence}}',
    [SHIPMENT_STATUSES.DOCUMENT_UPLOADED]: 'Document Uploaded {{sequence}}',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Shipment Completed',
    [SHIPMENT_POINT_STATUS.COMPLETE]: 'Shipment Completed',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Cancelled by Vendor',
    [DERIVED_SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Cancelled by Shipper',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Cancelled by Shipper',
    [SHIPMENT_STATUSES.CANCELLED]: 'Cancelled',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Unfulfilled',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Reassigned',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Unfulfilled',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Not Confirmed by Vendor',
    [JOB_STATUSES.CONFIRMATION_EXPIRED]: 'Confirmation Expired',
    [JOB_STATUSES.JOB_BIDDING]: 'Bidding',
    [JOB_STATUSES.JOB_UNASSIGNED]: 'Unassigned',
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'Finish Unloading {{sequence}}',

    [JOB_STATUSES.LATE_TO_ORIGIN]: 'Late to origin {{sequence}}',
    [JOB_STATUSES.LATE_TO_DESTINATION]: 'Late to destination {{seqeuence}}',

    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin {{squence}}',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination {{sequence}}',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Behind Schedule',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
    [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE]: 'Queue at Origin {{sequence}} Late',
    [DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE]: 'Queue at Destination {{seqeunce}} Late',
  },
  shipment_successfully_cancelled: 'Shipment successfully cancelled',
  shipment_successfully_saved: 'Shipment successfully saved',
  sku_group_contract_form: 'If you input the SKU group, this contract will apply to your orders for this SKU group.',
  sku_removal_msg: 'Removing this SKU will affect some modules.',
  some_file_download_failed: 'Failed to download some file',
  status_is_already_finish_unloading: 'Status is already Finish Unloading. Please Cancel to proceed',
  status_successfully_changed: 'Status successfully changed',
  status_successfully_updated: 'Status successfully updated',
  thank_you_for_your_rating: 'Thank you for your rating',
  time_spent_by_truck_at_loading_or_unloading_location: 'Time Spent by truck at loading or unloading location',
  truck_selection_switched_on: 'When switched on, Vendor will choose the truck type',
  to_enable_load_rating_document: 'To enable document upload from Load Rating Page',
  to_reactivate_premium_access_please_contact_kargo_customer_success: 'To reactivate premium access please contact Kargo customer success',
  to_set_up_detail_evaluation:
    'Set up the details below to help you evaluate shipment after the shipment has been completed.',
  to_set_up_rating_shipment: 'To set up which aspect(s) will be included when rating completed shipments',
  to_set_up_load_rating_shipment: 'To set up which criterial will be shown in Load Rating',
  transaction_recap_description: 'Disclaimer: Historical transactions and month-to-date transactions may differ from your invoice amount. Additional fees are excluded.',
  transporter_address_for_invoice_helper: 'Add customer address that will be added in invoice.',
  try_for_free: 'Try Kargo Nexus for free',
  user_access_type_description_map: {
    [DANONE_USER_ACCESS_TYPE.DANONE_ADMIN_3PL]: 'Access to create, allocate and monitor shipments.',
    [DANONE_USER_ACCESS_TYPE.DANONE_LOGISTIC]: 'Access to logistics specific modules',
    [DANONE_USER_ACCESS_TYPE.DANONE_PROCUREMENT]: 'Access to procurement specific modules',
    [DANONE_USER_ACCESS_TYPE.WAREHOUSE]: 'Access to save and update specific order statuses via QR code',
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: '-',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: '-',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Access to see, edit, and manage other employees.',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Access to see shipment and payment information.',
    [USER_ACCESS_TYPE.FINANCE]: 'Access to see statement of accounts.',
  },
  upload_contract_note_message_primary:
    'Please select your current CSV separator (comma, semicolon, etc.). The default is comma (,)',
  upload_contract_note_message_secondary:
    'Note: You can check you current CSV separator by opening the file in Notepad (or any other text editor).',
  upload_image: 'You can only upload JPG/JPEG/PNG file!',
  vendor_successfully_assigned: 'Vendor Successfully Assigned.',
  waiting_to_booked: 'Waiting to be booked',
  we_will_send_temporary_password: 'We will send a temporary password to the phone number above. Users can sign in and change the password later.',
  welcome_to_platform: 'Get Started',
  welcome_to_vendor_central: 'Welcome to {{name}}!',
  welcome_to_vendor_central_description: 'Let\'s start to manage your logistic process easier and more integrated one.',
  winner_cant_be_set: 'Winner can not be set',
  winner_successfully_selected: 'Winner Successfully Selected',
  you_already_set_the_price: 'You already set the price. Please reload this page.',
  you_have_a_new_shipment: 'You have a new shipment',
  your_premium_access_has_been_revoked: 'Your premium access has been revoked',
  please_upload_payment_proof: 'Please upload proof of payment here to assist our team in faster reconciliations.',
  column_payments_verified_by_kargo: 'This column refer to payments verified by Kargo',
  allowed_payment_proof_format: 'Format JPG, PNG, PDF. Max Size: 20 MB',
  drag_and_drop_file_to_box: 'Drag & Drop file into this box',
  this_is_history_of_payment_proof: 'This is the history of your proof of payments',
  are_you_sure_to_delete_proof: 'Are you sure to permanently delete this proof of payment?',
  shipper_has_invoice_past_due_date: 'Kindly note that there are overdue invoices in your account. Please settle the outstanding payments promptly and attach the payment proof for accurate processing and efficient account reconciliation. Thank you.',
  are_you_sure_to_cancellling_order: 'Are you sure about to cancelling order',
  this_action_erase_the_order_cannot_be_restored: 'This action will erase the order and cannot be restored or undo',
  reached_maximum_registered_employee_please_contact_kargo_customer_support: 'You have reached the maximum numbers of registered employee. Please contact Kargo Customer Support for further information.',
  error_title: 'Something went wrong',
  error_hint: 'Please report this error to our team and we will take a look at it',
  password_expiration_warning: 'Your password will expire in {{daysLeft}} days',
  password_expiration_warning_message: 'For security reason it\'s recommended to change your password every 6 months',
  password_expiration_warning_title: 'Please Change Your Password',
};
