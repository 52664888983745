import { get } from 'lodash-es';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const getUserProfile = data => {
  const employeeProfile = get(data, 'myEmployeeProfile');
  const companyDetails = get(data, 'myEmployeeProfile.company');
  const companyDocuments = get(data, 'companySettings.companyDocuments');
  const companyBankAccounts = get(data, 'companyBankAccounts');
  const enrolledCompanyKsuids = get(data, 'enrolledCompanies') || [];
  const companyNfsConfigs = get(data, 'companyNfsConfigs.companyConfigs') || [];

  return {
    defaultLocale: get(employeeProfile, 'user.defaultLocale'),
    email: get(employeeProfile, 'user.email') || '',
    ksuid: get(employeeProfile, 'ksuid') || '',
    name: get(employeeProfile, 'name') || '',
    phoneNumber: get(employeeProfile, 'user.phoneNumber') || '',
    userKsuid: get(employeeProfile, 'user.ksuid') || '',
    passwordExpiredAt: get(employeeProfile, 'user.passwordExpiredAt') || '',
    company: {
      accessType: get(employeeProfile, 'accessType', ''),
      accessTypes: get(employeeProfile, 'accessTypes', []),
      address: get(companyDetails, 'address') || '',
      email: get(companyDetails, 'email') || '',
      handle: get(companyDetails, 'handle'),
      hasConsentedCustomer: Boolean(get(companyDetails, 'hasConsentedCustomer')),
      isDofEligible: Boolean(get(companyDetails, 'isDofEligible')),
      isPodfEligible: Boolean(get(companyDetails, 'isPodfEligible')),
      ksuid: get(companyDetails, 'ksuid'),
      mainCompanyKsuid: get(companyDetails, 'companyReference.ksuid')
        ? get(companyDetails, 'companyReference.ksuid') : get(companyDetails, 'ksuid'),
      logo: {
        url: get(companyDetails, 'logo.url.full'),
        fileName: get(companyDetails, 'logo.fileName') || ''
      },
      metadata: get(companyDetails, 'metadata') || '',
      name: get(companyDetails, 'name') || '',
      phoneNumber: get(companyDetails, 'phoneNumber') || '',
      pricingSettings: {
        pricingRateType: get(companyDetails, 'pricingSettings[0].pricingRateType'),
        pricingRequiredVariables: get(companyDetails, 'pricingSettings[0].pricingRequiredVariables'),
        pricingIdempotentVariables: get(companyDetails, 'pricingSettings[0].pricingIdempotentVariables')
      },
      status: get(companyDetails, 'status'),
      type: get(companyDetails, 'type'),
      documents: {
        ktp: {
          url: get(companyDocuments, 'ktpFile.url.full'),
          fileName: get(companyDocuments, 'ktpFile.fileName') || '',
          uploadedAt: get(companyDocuments, 'ktpFile.uploadedAt.datetime')
        },
        npwp: {
          url: get(companyDocuments, 'npwpFile.url.full'),
          fileName: get(companyDocuments, 'npwpFile.fileName') || '',
          uploadedAt: get(companyDocuments, 'npwpFile.uploadedAt.datetime')
        },
        sppkp: {
          url: get(companyDocuments, 'sppkpFile.url.full'),
          fileName: get(companyDocuments, 'sppkpFile.fileName') || '',
          uploadedAt: get(companyDocuments, 'sppkpFile.uploadedAt.datetime')
        }
      },
      linkedBrokerageKsuid: get(companyDetails, 'linkedBrokerageKsuid'),
    },
    notificationMetadata: get(employeeProfile, 'notificationMetadata') || '',
    bankAccounts: Array.isArray(companyBankAccounts)
      ? companyBankAccounts.map(account => ({
        ksuid: get(account, 'ksuid'),
        accountHolder: get(account, 'accountHolder'),
        accountNumber: get(account, 'accountNumber'),
        isDefault: get(account, 'isDefault'),
        bank: {
          ksuid: get(account, 'bank.ksuid'),
          name: get(account, 'bank.name')
        }
      }))
      : [],
    enrolledCompanies: enrolledCompanyKsuids.map(({ ksuid } = {}) => ksuid),
    companyNfsConfigs
  };
};

export default getUserProfile;
